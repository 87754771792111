<template>
  <div class="admin-list">
    <h4 class="list-title">{{ $lang.app.deadlines }}</h4>
    <div class="admin-navbar align-right">
      <router-link to="/admin/deadlines/create" class="action-button">{{ $lang.app.create }}</router-link>
    </div>
    <div class="admin-table">
      <table>
        <thead>
        <tr>
          <th class="key">{{ $lang.app.title }}</th>
          <th>{{ $lang.app.registration_by_residence_start }}</th>
          <th>{{ $lang.app.registration_outside_residence_start }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="deadline in deadlines" :key="deadline.id">
          <td class="key">{{ deadline.name }}</td>
          <td>{{ $moment(deadline.registrationByResidenceStart).format('DD.MM.YYYY HH:mm:ss') }}</td>
          <td>{{ $moment(deadline.registrationOutsideResidenceStart).format('DD.MM.YYYY HH:mm:ss') }}</td>
          <td>
            <div class="action-buttons">
              <router-link :to="`/admin/deadlines/${deadline.id}`"
                           class="action-button" :title="$lang.app.view"><i class="bi-info-square"></i></router-link>
              <router-link :to="`/admin/deadlines/${deadline.id}/edit`"
                           class="action-button" :title="$lang.app.edit"><i class="bi-pencil-square"></i></router-link>
              <button class="action-button" :title="$lang.app.delete" @click="deleteRequest(deadline.id)"><i class="bi-x-square"></i></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <DeleteModal v-if="deleteId"
                 @close="deleteId = null"
                 @delete="deleteResource"/>
  </div>
</template>
<script>
import DeleteModal from '../../../components/admin/DeleteModal'
export default {
  components: {
    DeleteModal
  },
  data() {
    return {
      deadlines: [],
      deleteId: null,
    }
  },
  watch: {
    '$route'() {
      this.fetchResources()
    }
  },
  methods: {
    fetchResources() {
      this.$axios.get(`/academic-year/list`).then(({data}) => {
        this.deadlines = data
      })
    },
    deleteRequest(id) {
      this.deleteId = id
    },
    deleteResource() {
      this.$axios.delete(`/academic-year/${this.deleteId}`).then(() => {
        this.deleteId = null
        this.fetchResources()
        this.$snotify.success(this.$lang.app.resource_deleted_successfully);
      })
    }
  },
  mounted() {
    this.fetchResources()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/table.css';
</style>